import React, { Component, Suspense, useEffect } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import { CSpinner, useColorModes } from '@coreui/react-pro'
import './scss/style.scss'
import { useSelector } from 'react-redux'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Logout = React.lazy(() => import('./views/pages/login/Logout'))
const CargaAERP = React.lazy(() => import('./views/usuarios/CargaAERP'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {

  function isLogged() {
    try {
      const usuarioJson = localStorage.getItem('usuario');
      const usuario = JSON.parse(usuarioJson);
      return usuario.token && usuario.token !== ''
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  useEffect(() => {
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            exact
            path="/"
            name="Guard"
            element={<ProtectedRoute isAllowed={isLogged()} />}
          >
            <Route path="/" name="Default" element={<DefaultLayout />} />
          </Route>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/logout" name="Logout Page" element={<Logout />} />
          <Route exact path="/cargaAERP" name="Carga AERP" element={<CargaAERP />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Default" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
export default App
